var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',[_c('MasterHeader'),(_vm.hasNewSignupFlow)?_c('sign-up-modal'):_vm._e(),(!_vm.isLoaded)?_c('div',{staticClass:"loader"},[_vm._v("Loading...")]):_vm._e(),(_vm.isLoaded)?_c('div',{staticClass:"container container-wide"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"widget-splash splash-line"}),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"widget-splash splash-line"}),_c('div',{staticClass:"widget-header"},[_c('h4',{staticClass:"event_title"},[_c('router-link',{attrs:{"to":"/events"}},[_vm._v("Events list")]),_vm._v(" > "+_vm._s(_vm.currentEvent.event_name)+" ")],1)]),_c('div',{staticClass:"widget-body widget-suggestion px-5"},[_c('h2',{staticClass:"event_header"},[_vm._v("Event matching")]),_c('ul',_vm._l((_vm.currentEvent.attributes),function(attribute,index){return _c('li',{key:index},[_c('span',{staticClass:"list-item-counter"},[_vm._v(_vm._s(index + 1))]),_vm._v(_vm._s(attribute.title)+" ")])}),0),_c('p',{staticClass:"pb-3"},[_vm._v(" When the event is over, we will share your Excelerate profile details with the companies you selected ")]),_c('h5',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.selectedCompanies)+"/"+_vm._s(_vm.totalCompanies)+" Selected ")]),_c('div',{staticClass:"d-flex align-items-center image-company"},_vm._l((_vm.currentEvent.companies),function(company){return _c('img',{key:company.company_id,staticClass:"event_company_image",class:!!_vm.allInterestedCompanies.find(
                      function (item) { return +item.company_id === +company.company_id; }
                    )
                      ? 'active_image'
                      : 'd-block',attrs:{"src":company.company_event_logo ? company.company_event_logo : require('@/assets/Noimage.png'),"alt":company.company_name,"srcset":""},on:{"click":function($event){return _vm.handleCompanyModal(company)}}})}),0)])])])])]):_vm._e()],1),(_vm.showCompanyModal)?_c('div',[_c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"close-modal",on:{"click":function($event){_vm.showCompanyModal = false, _vm.currentInterestedCompany = {}}}},[_c('i',{staticClass:"fa fa-times"})]),_c('div',{staticClass:"modal-body text-center"},[_c('h4',{staticClass:"black font-weight-normal"},[_vm._v("I am interested in")]),_c('h3',{staticClass:"black py-3"},[_vm._v(_vm._s(_vm.selectedCompany.company_name))]),_c('h6',{staticClass:"text-left font-weight-bold my-3"},[_vm._v(" Connection purpose ")]),_c('ul',{staticClass:"text-left font-weight-bold black"},_vm._l((_vm.companyInterestsArray),function(interest){return _c('li',{key:interest.id},[_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.currentInterestedCompany.company_attributes[
                            interest.id
                          ]
                        ),expression:"\n                          currentInterestedCompany.company_attributes[\n                            interest.id\n                          ]\n                        "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                          _vm.currentInterestedCompany.company_attributes[
                            interest.id
                          ]
                        )?_vm._i(
                          _vm.currentInterestedCompany.company_attributes[
                            interest.id
                          ]
                        ,null)>-1:(
                          _vm.currentInterestedCompany.company_attributes[
                            interest.id
                          ]
                        )},on:{"change":function($event){var $$a=
                          _vm.currentInterestedCompany.company_attributes[
                            interest.id
                          ]
                        ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.currentInterestedCompany.company_attributes, 
                            interest.id
                          , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.currentInterestedCompany.company_attributes, 
                            interest.id
                          , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.currentInterestedCompany.company_attributes, 
                            interest.id
                          , $$c)}}}}),_c('span',{staticClass:"slider round"})]),_vm._v(" "+_vm._s(interest.name)+" ")])}),0),_c('div',{staticClass:"d-block"},[(_vm.isProcessing)?_c('span',{staticClass:"small_loader"}):_vm._e()]),(_vm.hasError)?_c('div',{staticClass:"alert alert-danger mt-3"},[_vm._v(" Please select at least one of the interests. ")]):_vm._e(),(!_vm.currentInterestedCompany.id)?_c('button',{staticClass:"btn pink-button",on:{"click":_vm.handleAdd}},[_vm._v(" Add ")]):_c('div',{},[_c('button',{staticClass:"btn pink-button",on:{"click":_vm.handleAdd}},[_vm._v(" Update ")]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.deleteCompany(_vm.currentInterestedCompany.id)}}},[_vm._v(" Remove ")])])])])])])])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }