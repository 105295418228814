<template>
  <div class="">
    <div>
      <MasterHeader />
      <sign-up-modal v-if="hasNewSignupFlow"/>
      <div v-if="!isLoaded" class="loader">Loading...</div>
      <div class="container container-wide" v-if="isLoaded">
        <div class="row">
          <div class="widget-splash splash-line"></div>
          <div class="col-12">
            <div class="widget">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4 class="event_title">
                  <router-link to="/events">Events list</router-link> >
                  {{ currentEvent.event_name }}
                </h4>
              </div>
              <div class="widget-body widget-suggestion px-5">
                <h2 class="event_header">Event matching</h2>
                <ul>
                  <li
                    v-for="(attribute, index) in currentEvent.attributes"
                    :key="index"
                  >
                    <span class="list-item-counter">{{ index + 1 }}</span
                    >{{ attribute.title }}
                  </li>
                </ul>
                <p class="pb-3">
                  When the event is over, we will share your Excelerate profile
                  details with the companies you selected
                </p>
                <h5 class="text-right">
                  {{ selectedCompanies }}/{{ totalCompanies }} Selected
                </h5>
                <div class="d-flex align-items-center image-company">
                  <img
                    @click="handleCompanyModal(company)"
                    v-for="company in currentEvent.companies"
                    :key="company.company_id"
                    :src="company.company_event_logo ? company.company_event_logo : require('@/assets/Noimage.png')"
                    :alt="company.company_name"
                    class="event_company_image"
                    :class="
                      !!allInterestedCompanies.find(
                        (item) => +item.company_id === +company.company_id
                      )
                        ? 'active_image'
                        : 'd-block'
                    "
                    srcset=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCompanyModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div @click="showCompanyModal = false, currentInterestedCompany = {}" class="close-modal">
                  <i class="fa fa-times"></i>
                </div>
                <div class="modal-body text-center">
                  <h4 class="black font-weight-normal">I am interested in</h4>
                  <h3 class="black py-3">{{ selectedCompany.company_name }}</h3>
                  <h6 class="text-left font-weight-bold my-3">
                    Connection purpose
                  </h6>
                  <ul class="text-left font-weight-bold black">
                    <li
                      v-for="interest in companyInterestsArray"
                      :key="interest.id"
                    >
                      <label class="switch">
                        <input
                          type="checkbox"
                          v-model="
                            currentInterestedCompany.company_attributes[
                              interest.id
                            ]
                          " />
                        <span class="slider round"></span
                      ></label>
                      {{ interest.name }}
                    </li>
                  </ul>
                  <div class="d-block">
                    <span v-if="isProcessing" class="small_loader"></span>
                  </div>
                  <div
                    class="alert alert-danger mt-3"
                    v-if="hasError"
                  >
                    Please select at least one of the interests.
                  </div>
                  <button
                    v-if="!currentInterestedCompany.id"
                    @click="handleAdd"
                    class="btn pink-button"
                  >
                    Add
                  </button>

                  <div class="" v-else>
                    <button @click="handleAdd" class="btn pink-button">
                      Update
                    </button>
                    <button
                      @click="deleteCompany(currentInterestedCompany.id)"
                      class="btn"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import MasterHeader from '@/components/MasterHeader.vue';
import { mapState } from 'vuex';
import { HTTP } from '../../axios/axios';
import SignUpModal from '../../components/signUpModal.vue';

export default {
  components: {
    MasterHeader,
    SignUpModal,
  },
  data() {
    return {
      isLoaded: false,
      selectedCompany: null,
      isProcessing: false,
      allInterestedCompanies: null,
      showCompanyModal: false,
      hasError: false,
      selectedCompanies: 0,
      currentInterestedCompany: {},
      companyInterestsArray: [
        { name: 'Full-time job', id: 'fullTimeJob' },
        { name: 'Work aboard', id: 'workAboard' },
        { name: 'Internship', id: 'internship' },
        { name: 'Project collaboration', id: 'projectCollaboration' },
        {
          name: 'Bachelor/Master Collaboration',
          id: 'bachelorOrMasterCollaboration',
        },
        { name: 'Student job', id: 'studentJob' },
        { name: 'Trainee-/Graduate programme', id: 'traineeGraduateProgramme' },
      ],
    };
  },
  mounted() {
    this.getStudentCompanyInterests();
    if (!this.event.events) {
      this.$store.dispatch('getEvents').then(() => (this.isLoaded = true));
    } else {
      this.isLoaded = true;
    }
  },
  computed: {
    ...mapState(['user', 'event']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    currentEvent() {
      const name = this.$route.name;
      const id = this.$route.params.id;
      const events = this.event.events[name];
      return events.find((event) => +event.event_id === +id);
    },
    totalCompanies() {
      return this.currentEvent.companies.length;
    },
  },
  methods: {
    handleCompanyModal(company) {
      let currentCompanyData;
      this.currentInterestedCompany = {};
      if (
        this.allInterestedCompanies &&
        this.allInterestedCompanies.length > 0 &&
        this.allInterestedCompanies.find(
          (item) => +item.company_id === +company.company_id
        )
      ) {
        currentCompanyData = this.allInterestedCompanies.find(
          (item) => +item.company_id === +company.company_id
        );
        this.currentInterestedCompany = currentCompanyData;
        if (typeof currentCompanyData.company_attributes === 'string') {
          this.currentInterestedCompany.company_attributes = JSON.parse(
            currentCompanyData.company_attributes
            );
          }
        // console.log(JSON.parse(
        //   currentCompanyData.company_attributes
        // ))
        console.log(currentCompanyData)
        this.currentInterestedCompany.created_at = null;
        this.currentInterestedCompany.updated_at = null;
      } else {
        this.currentInterestedCompany = {
          user_id: localStorage.getItem('uid'),
          id: null,
          event_id: this.currentEvent.event_id,
          company_id: company.company_id,
          company_attributes: {
            fullTimeJob: false,
            workAboard: false,
            internship: false,
            projectCollaboration: false,
            bachelorOrMasterCollaboration: false,
            studentJob: false,
            traineeGraduateProgramme: false,
          },
        };
      }
      this.selectedCompany = company;
      this.showCompanyModal = true;
    },
    async getStudentCompanyInterests() {
      try {
        const res = await HTTP.get(
          `getEvent/${this.$route.params.id}/${localStorage.getItem('uid')}`
        );
        this.allInterestedCompanies = res.data.data.payload;
        this.selectedCompanies = this.allInterestedCompanies.length;
      } catch (error) {
        //
      }
    },
    async handleAdd() {
      try {
          this.hasError = false;
        if (
          !this.currentInterestedCompany.company_attributes.fullTimeJob &&
          !this.currentInterestedCompany.company_attributes.workAboard &&
          !this.currentInterestedCompany.company_attributes.internship &&
          !this.currentInterestedCompany.company_attributes.projectCollaboration &&
          !this.currentInterestedCompany.company_attributes.bachelorOrMasterCollaboration &&
          !this.currentInterestedCompany.company_attributes.studentJob &&
          !this.currentInterestedCompany.company_attributes.traineeGraduateProgramme
        ) {
          this.hasError = true;
          return;
        }
        this.isProcessing = true;
        await HTTP.post('addStudentEvent', this.currentInterestedCompany).then(
          () => {
            this.isProcessing = false;
            this.showCompanyModal = false;
            this.getStudentCompanyInterests();
          }
        );
      } catch (error) {
        //
      }
    },
    async deleteCompany(id) {
      try {
        this.isProcessing = true;
        await HTTP.delete(`deleteStudentEvent/${id}`).then(() => {
          this.showCompanyModal = false;
          this.isProcessing = false;
          this.getStudentCompanyInterests();
        });
      } catch (error) {
        //
      }
    },
  },
};
</script>
<style scoped>
.thesis-body {
  padding: 2rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}
.text-bold {
  font-weight: 700;
}
.thesis-button {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
  background: transparent;
}
table tr th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}
table tr th:not(:first-child) {
  background-color: #fcfcfc;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
li {
  list-style: none;
}
ul {
  padding: 0;
}
ul > li {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-size: 16px;
}
.list-item-counter {
  margin-right: 1rem;
  color: white;
  margin-bottom: 5px;
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
}
.image-company {
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.event_company_image {
  width: 18%;
  /* height: auto; */
    object-fit: cover;
    height: 120px;

  cursor:pointer;
}
@media (max-width: 767px) {

  ul > li {
    display: block;
    margin-top:14px;
  }
  .image-company {
    width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }
  .event_company_image {
    width: 45%;
    height: auto;
    cursor: pointer;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  max-width: 500px;
  padding: 5%;
  border-radius: 10px;
  position: relative;
}
.close-modal {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #fff;
  /* box-shadow: 0px 5px 5px #ccc; */
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 15px;
  cursor: pointer;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3aa948;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.pink-button {
  background-color: #bc1e73;
  color: #fff;
  width: 100px;
}
.small_loader {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 5px solid #bc1e73;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.active_image {
  border: 6px solid #bc1e73;
  border-radius: 5px;
}
.event_title{
  /* text-overflow: ellipsis; */
  height: auto !important;
}
.event_header{
  margin: 20px 0;
}
@media (min-width: 799px) {
  .event_header{
    text-align:center;
  }
}
</style>
